allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.settings = main.gui.settings || {};

    var g = main.gui.settings ;

    var avatarCropper;
    var avatarCropperUpdateTimeoutId;

    function readFile(input, cropper) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                cropper.bind({
                    url: e.target.result
                }).then(function(){

                });
            };

            reader.readAsDataURL(input.files[0]);
            // remove file so it doesn't get uploaded
            input.value = '';
        }
        else {
            main.utils.showError("Sorry - you're browser doesn't support the FileReader API");
        }
    }

    g.initAvatarCropper = function () {

        $('#uploadAvatar').on('change', function () {
            startCropper($('#jsAvatarCropper')[0]);
            readFile(this, avatarCropper);
        });

        $('.jsUserAvatarDelete').on('click', function(){
            $('#avatar').val("delete");
            $('.jsUserAvatar').html('<span class="fa fa-user" aria-hidden="true"></span>');
            $(this).addClass('hidden');
        });
    };

    function startCropper($cropperContainer) {
        if (avatarCropper !== undefined) {
            return ;
        }

        avatarCropper = new Croppie($cropperContainer, {
            viewport: {width: 300, height: 300, type: 'circle'},
            boundary: {width: 310, height: 310},
            enableOrientation: true,
            update: function (croppie) {
                // use timeout so it doesn't do it constantly while user is dragging
                clearTimeout(avatarCropperUpdateTimeoutId);
                avatarCropperUpdateTimeoutId = setTimeout(function () {
                    avatarCropper.result({
                        type: 'canvas',
                        size: {width:300, height:300},
                        format: 'png',
                        circle: false
                    }).then(function (resp) {
                        $('#avatar').val(resp);
                        $('.jsUserAvatar').html($('<img class="img-responsive img-circle">').attr('src', resp));
                    });
                }, 300);
            }
        });

        //
        $('.jsUserAvatarCropperContainer, .jsUserAvatarCropperSelect').toggleClass('hidden');

        // init rotate buttons
        $('.jsCropperRotate').on('click', function(ev) {
            var $cropperContainer = $('.jsUserAvatarCropperContainer');
            var degrees = parseInt($(this).data('rotate'));
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            if (isNaN(newRotation)) {
                newRotation = 0;
            }
            newRotation += degrees;
            if (newRotation === 90 || newRotation === 270) {
                if (parseInt($cropperContainer.data('rotation')) > newRotation) {
                    newRotation--;
                } else {
                    newRotation++;
                }
            }
            $cropperContainer.data('rotation', newRotation);
            avatarCropper.rotate(newRotation);

        });
    }

    g.initOutOfOffice = function(){

        $('.jsOutOfOfficeSwitch').on('change', function(){
            var outOfOfficeEnabled = ($(this).is(':checked'));

            // hide/show options that are only visible when list is public
            $('.jsOutOfOfficeOptions').toggle(outOfOfficeEnabled);
        });


        var locale = $('html').attr('lang');
        var options = {
            minDate: moment().format('MM/DD/YYYY'),
            format: 'DD-MM-YYYY',
            locale: locale,
            ignoreReadonly: true,
            showClear: true,
            showTodayButton: true,
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar',
                clear: 'fa fa-trash',
                close: 'fa fa-close'
            }
        };

        var datePicker = $('#out_of_office_end_at');
        datePicker.datetimepicker(options);
    }

    return main;

}(window.allcaps || {}, jQuery);
