allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.correction = main.gui.correction || {};

    var g = main.gui.correction;

    g.init = function () {
        // start all croppers
        $('.jsCropper').each(function () {
            startCropper($(this)[0], undefined, $(this).closest('.jsCropperContainer'));
        });

        $('.jsSelectAll').on('click', function () {
            var active =$(this).prop('checked');
            if(active){
                $('input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox, .jsEmptyValue)').prop('checked', true);

            } else {
                $('input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox)').prop('checked', false);
            }

            checkSubmitButton();
        });
        //
        $('#jsCorrectionForm input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox)').change(function () {
            checkSubmitButton();
        });
    };

    function checkSubmitButton() {
        var submitBtn = $('#jsCorrectionForm button:submit');
        submitBtn.addClass('btn-danger').removeClass('btn-default').text(submitBtn.data('zero'));


        if ($("#jsCorrectionForm input:checkbox:checked:not(.jsSelectAll, .jsIgnoreCheckbox)").length > 0) {
            submitBtn.removeClass('btn-danger').addClass('btn-success').text(submitBtn.data('many'))
        }
    }

    function updateCropData(instance, rotation, $cropperContainer) {
        var points = instance.get().points;
        var data = {
            'x1': points[0],
            'y1': points[1],
            'x2': points[2],
            'y2': points[3],
            'rotation': rotation % 360
        };
        $cropperContainer.find('.jsImageCropData').val(JSON.stringify(data));
    }

    function startCropper($cropperCanvasDiv, instance, $cropperContainer) {

        if (instance !== undefined) {
            return instance;
        }

        // init the cropper
        instance = new Croppie($cropperCanvasDiv, {
            viewport: {width: 500, height: 500, type: 'circle'},
            boundary: {width: 510, height: 510},
            enableOrientation: true,
            update: function (croppie) {
                updateCropData(instance, $cropperContainer.data('rotation'), $cropperContainer);
            }
        });


        var instructions = JSON.parse($cropperContainer.find('.jsImageCropData').val());

        fetch($cropperContainer.find('.jsImageCropUrl').val())
            .then(res => res.arrayBuffer())
            .then(arrayBuffer => {
                instance.bind({
                // remove exif data so cropper doesn't auto rotate image and leaves rotation to 0 resulting in out of sync 'rotation'
                // and thus failing creating correct thumbs
                url: removeExifData(arrayBuffer),
                points: [
                    instructions.x1,
                    instructions.y1,
                    instructions.x2,
                    instructions.y2
                ]
            }).then(function () {
                instance.rotate(instructions.rotation);
                var newRotation = instructions.rotation;
                $cropperContainer.data('rotation', newRotation);
                updateCropData(instance, newRotation, $cropperContainer);
            });
        });

      /*  instance.bind({
            url: $cropperContainer.find('.jsImageCropUrl').val(),
            points: [
                instructions.x1,
                instructions.y1,
                instructions.x2,
                instructions.y2
            ]
        }).then(function () {
            instance.rotate(instructions.rotation);
            var newRotation = instructions.rotation;
            $cropperContainer.data('rotation', newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });*/

        // init rotate buttons
        $cropperContainer.find('.jsCropperRotate').on('click', function (ev) {
            var degrees = parseInt($(this).data('rotate'));
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            newRotation += degrees;
            newRotation = normaliseRotationValue(newRotation, parseInt($cropperContainer.data('rotation')));

            // change slider value
            $cropperContainer.find('.jsRotationSlider').val(newRotation).change();
        });

        // rotate cropper + store rotation
        $cropperContainer.find('.jsRotationSlider').on('change', function (ev) {
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            newRotation = normaliseRotationValue(newRotation, parseInt($cropperContainer.data('rotation')));

            $cropperContainer.data('rotation', newRotation);
            instance.rotate(newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });

        return instance;
    }

    function normaliseRotationValue(value, oldValue){
        if (isNaN(value)) {
            value = 0;
        }
        if(value < 0){
            value += 360;
        }
        if(value >= 360){
            value %= 360;
        }
        if (value === 90 || value === 270) {
            if (oldValue > value) {
                value--;
            } else {
                value++;
            }
        }

        return value;
    }
    function removeExifData(result){
        var dv = new DataView(result);
        var offset = 0, recess = 0;
        var pieces = [];
        var i = 0;
        if (dv.getUint16(offset) == 0xffd8){
            offset += 2;
            var app1 = dv.getUint16(offset);
            offset += 2;
            while (offset < dv.byteLength){
                //console.log(offset, '0x'+app1.toString(16), recess);
                if (app1 == 0xffe1){

                    pieces[i] = {recess:recess,offset:offset-2};
                    recess = offset + dv.getUint16(offset);
                    i++;
                }
                else if (app1 == 0xffda){
                    break;
                }
                offset += dv.getUint16(offset);
                var app1 = dv.getUint16(offset);
                offset += 2;
            }
            if (pieces.length > 0){
                var newPieces = [];
                pieces.forEach(function(v){
                    newPieces.push(result.slice(v.recess, v.offset));
                }, this);
                newPieces.push(result.slice(recess));
                var br = new Blob(newPieces, {type: 'image/jpeg'});

                return  URL.createObjectURL(br);
            }
        }

        return URL.createObjectURL(new Blob([result]));
    }

    return main;

}(window.allcaps || {}, jQuery);
