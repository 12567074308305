allcaps = function (main, $) {

    main.user = main.user || {};

    var u = main.user;

    u.resendVerificationEmail = function () {
        var $btn = $('.jsResendVerificationEmailButton');
        var prevBtnContent = $btn.html();
        $btn.attr('disabled', 'disabled').prepend('<i class="loader" aria-hidden="true"></i> ');

        $.post(main.utils.route('front.ajax.user.resend_verification_email'))
            .success(function (data) {
                var error = main.utils.getAjaxError(data);
                if( error ){
                    main.utils.showError(error);
                    return;
                }

                main.utils.showSuccess(trans('auth.verify_email.email_sent'));

            })
            .error(function (data) {
                main.utils.showError(main.utils.getAjaxError(data));
            })
            .always(function (data) {
                // remove spinner, and enable button
                $btn.removeAttr('disabled').html(prevBtnContent);
            });
    };


    u.markNotificationsAsRead = function () {
        var $btn = $('.jsMarkNotificationsAsRead');
        var prevBtnContent = $btn.html();
        $btn.attr('disabled', 'disabled').prepend('<i class="loader" aria-hidden="true"></i> ');

        $.post(main.utils.route('front.ajax.user.mark_notifications_as_read'))
            .success(function (data) {
                var error = main.utils.getAjaxError(data);
                if( error ){
                    main.utils.showError(error);
                    return;
                }

                //main.utils.showSuccess(trans('auth.verify_email.email_sent'));
                $('.jsNotificationsContainer').hide();
            })
            .error(function (data) {
                main.utils.showError(main.utils.getAjaxError(data));
            })
            .always(function (data) {
                // remove spinner, and enable button
                $btn.removeAttr('disabled').html(prevBtnContent);
            });
    };


    return main;

}(window.allcaps || {}, jQuery);
