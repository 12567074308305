allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.inbox = main.gui.inbox || {};

    var n = main.gui.inbox;
    n.init = function(){
        n.initAddressButton();
        n.initEditMessages();
    };

    n.initAddressButton = function () {
        $('.jsAddAddress').on('click', function(e){
            main.pde(e);

            var locale = $('html').attr('lang');

            var $2 = $(e.target);
            var name = $2.data('name');
            var street = $2.data('street');
            var streetNumber = String($2.data('streetNumber'));
            var streetBox = String($2.data('streetBox'));
            var zip = $2.data('zip');
            var city = $2.data('city');
            var country = $2.data('country');
            var target = $2.data('target');

            if(street.length > 0){
                var address = '\n';
                address += name +'\n';
                switch(locale){
                    case 'fr':
                        var fullStreet = streetNumber + ' '+ street;
                        if(streetBox.length > 0){
                            fullStreet += ' - '+streetBox;
                        };
                        break;
                    default:
                        var fullStreet = street +' '+ streetNumber;

                        if(streetBox.length > 0){
                            fullStreet += '/'+streetBox;
                        };
                        break;
                }

                address += fullStreet.trim() +'\n';
                address += zip +' '+city +'\n';
                address += country +'\n';

                $(target).val($(target).val() + address);
            } else {
                allcaps.utils.showError($('.jsAddAddressWarning').html());
            }
        })
    };

    n.initEditMessages = function () {

        $('.jsEditMessageButton').on('click', function(e){
            e.preventDefault();
           var messageId = $(this).data('id');
           $('.jsMessage[data-id="'+messageId+'"]').hide();

            $('.jsEditMessage[data-id="'+messageId+'"]')
                .removeClass('hide')
                .show();

            main.gui.autoGrowTextarea($('#text_'+messageId)[0]);
        });
        $('.jsCancelEditButton').on('click', function(e){
            var messageId = $(this).data('id');
            $('.jsMessage[data-id="'+messageId+'"]').show();
            $('.jsEditMessage[data-id="'+messageId+'"]').hide();
        });
    }


    return main;

}(window.allcaps || {}, jQuery);
