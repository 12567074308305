allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.profile = main.gui.profile || {};

    var g = main.gui.profile ;

    g.init = function () {

        // send user a message
        // show warning if user hasn't verified his/her email
       $('#message, #subject').on('focus', function(){
           $('.jsUnverifiedEmailAlert').fadeIn();
       });

    };

    return main;

}(window.allcaps || {}, jQuery);
