allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.lists = main.gui.lists || {};

    var n = main.gui.lists;

    n.reloadListModalContent = function(modal, capSlug, sort) {
        var modalContent = modal.find('.jsModalContent')

        modalContent.html('<i class="loader"></i>');

        var ajaxOptions = {
            'method': 'GET',
            'url': main.utils.route('front.ajax.cap.extra_lists').replace('_CAP_', capSlug),
            'data': {'sortBy': sort}
        };

        $.ajax(ajaxOptions)
            .error(function (xhr) {
                main.utils.showError(main.utils.getAjaxError(xhr));
            })
            .done(function (data) {
                modalContent.html(data);
            })
            .always(function () {
            });
    }

    n.init = function () {

        // if user clicks on an action in the action dropdown in the list tha triggers a form,
        // it should be disabled and show a loader icon
        $('.jsFormDisableActionDropdown').on('submit', function(){
            $('.jsActionDropdown')
                .addClass("disabled")
                .prepend('<i class="loader" aria-hidden="true"></i> ');
        });

        $('#listsModal').on('show.bs.modal', function (event) {
            // change data-id for each list to add/remove the correct cap

            var button = $(event.relatedTarget); // Button that triggered the modal
            var capSlug = button.data('id'); // Extract info from data-* attributes
            var modal = $(this);

           n.reloadListModalContent(modal, capSlug);
        });


        $('.jsIsPublicList').on('change', function(){
            var isLinkVisible = ($(this).val() !== 2); //IS_PUBLIC_FOR_NONE
            var isPublicForOneUser = ($(this).val() == 4); //IS_PUBLIC_FOR_ONE

            // hide/show options that are only visible when list is public
            $('.jsIsPublicListContainer').toggle(isLinkVisible);
            $('.jsSelectListUser').toggle(isPublicForOneUser);
        });
    };

    return main;

}(window.allcaps || {}, jQuery);
