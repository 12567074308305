allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.community = main.gui.community || {};

    var n = main.gui.community;

    n.init = function () {
        initMap();
    };

    function initMap() {

        var $map = $(n.mapElement);
        // icon
        var options = {
            zoom: $map.data('zoom'),
            center: {lat: $map.data('lat'), lng: $map.data('lng')},
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map(n.mapElement, options);

        //set bounds
        var bounds = new google.maps.LatLngBounds();

        var infowindow = new google.maps.InfoWindow({'content': ''});

        // Add some markers to the map.
        // Note: The code uses the JavaScript Array.prototype.map() method to
        // create an array of markers based on a given "locations" array.
        // The map() method here has nothing to do with the Google Maps API.
        //var iconPath = '{{ asset('storage/a/_ICON_') }}';
        var markers = n.locations.map(function (location, i) {
            var latlng = {lat: location['lat'], lng: location['lng']};
            var icon = null;
            var label = {'text': location['label'], 'fontSize': '12px'};
            if (location['icon']) {
                label = '';
                icon = new google.maps.MarkerImage(location['icon'], null, null, null, new google.maps.Size(35, 35));
            }
            var listsOutput = '<br><br>';
            for (i = 0; i < location['lists'].length; i++) {
                var list = location['lists'][i];
                listsOutput += '<div>' +list['type'] + ' <span class="badge">'+list['total']+'</span></div>';
            }

            var marker = new google.maps.Marker({
                position: latlng,
                title: location['title'],
                label: label,
                map: map,
                icon: icon,
                url: location['url'],
                info: '<a href="'+location['url']+'"><strong><span class="small"><i class="fa fa-circle text-' + location['status'] +' small" ></i></span> ' + location['title'] +'</strong>'+listsOutput+'</a>'
            });

            // on click marker
            google.maps.event.addListener(marker, 'click', function () {
                //window.location.href = allcaps.utils.route('front.user').replace('_ID_', this.url);
                infowindow.setContent(this.info);
                infowindow.open(map, marker);
                return false;
            });

            bounds.extend(latlng);
            return marker;
        });
        // // fit all markers in the screeen unless we're zoomed in by default
        // if (options.zoom == 4) {
        //     map.fitBounds(bounds);
        // }

        //autocomplete settings
        if (n.searchinput) {

            var countryRestrict = {'country': ['be', 'nl', 'fr', 'es', 'it', 'de', 'ch', 'lu']};

            var autocomplete = new google.maps.places.Autocomplete(
                (n.searchinput),
                {
                    types: ['(regions)'],
                    componentRestrictions: countryRestrict
                }
            );
            map.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementById('searchContainer'));

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();
                if (place.geometry) {
                    map.panTo(place.geometry.location);
                    map.setZoom(11);
                } else {
                    n.searchinput.placeholder = "Search";
                }
            });

            /**
             * Enable the search on enter event
             * This will trigger the map search for the first item in the list
             */

            $(document).keypress(function (event) {
                if (event.keyCode == 13) {
                    if ($('.pac-container .pac-item.pac-selected').length) {
                        firstResult = $('.pac-container .pac-item.pac-selected').text();
                    } else {
                        firstResult = $(".pac-container .pac-item:first").text();
                    }

                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({"address": firstResult}, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            var lat = results[0].geometry.location.lat(),
                                lng = results[0].geometry.location.lng(),
                                placeName = results[0].address_components[0].long_name,
                                latlng = new google.maps.LatLng(lat, lng);

                            $(".pac-container").css("display", "none");
                            //// TODO: find out how to change this to searchinput
                            $("#searchMap").val(placeName);
                            map.panTo(latlng);
                            map.setZoom(11);
                            return false;
                        }
                    });
                }
            });
        }


        // limit zoom
        map.addListener('zoom_changed', function () {
            if (map.getZoom() > 17) {
                map.setZoom(17);
            }
        });

        // Add a marker clusterer to manage the markers.
        var markerCluster = new MarkerClusterer(map, markers, {imagePath: '/img/google/maps/m'});


        var boundsChangedTimeoutId = null;
        google.maps.event.addListener(map, 'bounds_changed', function () {

            // wait a bit, otherwise the map stutters
            window.clearTimeout(boundsChangedTimeoutId);
            boundsChangedTimeoutId = window.setTimeout(function() {

                var shownUserCount = 0;
                $('.jsMapUser').hide();
                $.each(n.locations, function (index, place) {
                    var placeLocation = new google.maps.LatLng(place.lat, place.lng);
                    var onMap = map.getBounds().contains(placeLocation);
                    shownUserCount += onMap ? 1 : 0;

                    $('[data-id="' + place.username + '"]').toggle(onMap);
                    if (shownUserCount === 10) {
                        return false
                    }
                });
            }, 350);

            $('#searchContainer').show();
        });

    }

    return main;

}(window.allcaps || {}, jQuery);
