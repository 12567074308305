allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.agenda = main.gui.agenda || {};

    var n = main.gui.agenda;

    n.init = function () {
        var locale = $('html').attr('lang');
        var format = 'DD-MM-YYYY';
        var start = $('#start');
        var highlightedDates = start.data('highlightedDates');
        var options = {
            format: format,
            locale: locale,
            ignoreReadonly: true,
            inline: true,
            //showTodayButton: true,
            minDate: new Date(),
            toolbarPlacement: 'top',
            highlightedDates: highlightedDates,
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar',
                clear: 'fa fa-close',
                close: 'fa fa-close'
            }
        };

        start.datetimepicker(options);


        // affix sidebar
        // https://stackoverflow.com/questions/15228224/twitter-bootstrap-affix-how-to-stick-to-bottom
        var headerHeight = $('.navbar').outerHeight(true); // true value, adds margins to the total height
        var footerHeight = $('footer').outerHeight(true) + 30;
        $('.events-affix-sidebar').affix({
            offset: {
                top: headerHeight,
                bottom: footerHeight
            }
        }).on('affix.bs.affix', function () { // before affix
            $(this).css({
                /*'top': headerHeight,*/    // for fixed height
                'width': $(this).outerWidth()  // variable widths
            });
        }).on('affix-bottom.bs.affix', function () { // before affix-bottom
            $(this).css('bottom', 'auto'); // THIS is what makes the jumping
        });
    };

    n.initForm = function () {

        initDateTimePickers('DD-MM-YYYY HH:mm');
    };


    function initDateTimePickers(format, minDate){
        var locale = $('html').attr('lang');
        var options = {
            format: format,
            locale: locale,
            ignoreReadonly: true,
            stepping: 15,
            icons: {
                time: "fa fa-clock-o",
                date: "fa fa-calendar",
                up: "fa fa-arrow-up",
                down: "fa fa-arrow-down",
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right',
                today: 'fa fa-calendar',
                clear: 'fa fa-trash',
                close: 'fa fa-close'
            }
        };

        var start = $('#start');
        start.datetimepicker(options);

        var end = $('#end');
        var options2 = $.extend({}, options);
        options2['useCurrent'] = false;
        options2['minDate'] = start.data("DateTimePicker").date();
        options2['widgetPositioning'] = {horizontal:'right'};
        end.datetimepicker(options2);

        // changes
        start.on("dp.change", function (e) {
            end.data("DateTimePicker").minDate(e.date);
            if(end.data("DateTimePicker").date() < start.data("DateTimePicker").date()){
                end.val(start.data("DateTimePicker").date().format(format))
            };
        });

    }
    return main;

}(window.allcaps || {}, jQuery);
