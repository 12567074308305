allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.notes = main.gui.notes || {};

    var n = main.gui.notes;

    n.init = function () {
        $('.jsDeleteNote').on('click', function(event){
            event.preventDefault();

            var $btn = $(this);
            if ($btn.attr('disabled')) {
                return;
            }
            // disable btn
            var prevBtnContent = $btn.html();
            $btn.attr('disabled', 'disabled').html('<i class="loader" aria-hidden="true"></i>');

            var url = main.utils.route('front.ajax.notes.change');
            url = url.replace('_SLUG_', $btn.data('id'));
            url = url.replace('_TYPE_', $btn.data('type'));
            $.post(
                url,
                {
                    note: ''
                })

                .success(function (data) {
                    var error = main.utils.getAjaxError(data);
                    if( error ){
                        main.utils.showError(error);
                        return;
                    }

                    main.utils.showSuccess(data.data.message);
                    //remove the item visually from the list
                    $btn.closest('a').remove();

                })
                .error(function (data) {
                    main.utils.showError(main.utils.getAjaxError(data));
                })
                .always(function (data) {
                    // remove spinner, and enable button
                    $btn.removeAttr('disabled').html(prevBtnContent);
                });
        });

       /*
*/
    };

    return main;

}(window.allcaps || {}, jQuery);
