allcaps = function (main, $, dictionary, routes) {

    main.utils = main.utils || {};

    var u = main.utils;

    u.getAjaxError = function (data)     {

        if (data === undefined || data.data === undefined || data.error_count === undefined || data.error_count > 0) {
            return data.errors ? data.errors[0].description : trans('error.title.general','Something went wrong');
        }
        return null;
    };

    u.showError = function (message,title) {
        return $.notify({
            title: '<i class="fa fa-warning" aria-hidden="true"></i> ' + (title || ''),
            message: message || ''
        }, {
            type: "warning"
        });
    };

    u.showSuccess = function (message, title) {
        return $.notify({
            title: '<i class="fa fa-check" aria-hidden="true"></i> ' + (title || ''),
            message: message || ''
        }, {
            type: "success",
            delay: 1500
        });
    };

    u.translate = function(key, fallback){
        if(dictionary[key] !== undefined){
            return dictionary[key];
        }
        return fallback || '';
    };

    u.route = function(route){
        if(routes[route] !== undefined){
            return routes[route];
        }
        return null;
    };

    /**
     * Warn if user made changes.
     * Need to call u.changesMade() in order to trigger a change
     */
    u.warnOnChanges = function () {

        // remember we made changes on all elements with this attribute
        $('[data-warn-on-unsaved-changes]').on('change', function(){
            allcaps.hasChanges = true;
        });
        // if we submit a form, ignore the changes we made, since we'll save them
        $(document).on('submit', function(event) {
            main.utils.changesMade(false); // don't trigger the changes warning on submitting the form
        });

        // warn when changes occurred and user wants to leave
        window.onbeforeunload = function () {
            if (main.hasChanges) return 'You have unsaved changes. Are you sure?';
        };
    };

    /**
     * Set the hasChanges
     * @param bool bool defaults to true
     */
    u.changesMade = function (bool) {
        main.hasChanges = bool === undefined ? true : bool;
    };
    u.hasChanges = function () {
        return main.hasChanges;
    };

    /**
     * Scroll smoothly to an anchor inside the page.
     */
    u.initSmoothAnchorScrolling = function(){

        var $root = $('html, body');

        function smoothScrollingTo(target) {
            try {
                if(target == undefined || target == '' || target == '#' || $(target).offset() == undefined){
                    return;
                }
            }
            catch(err) {
                return;
            }
            var pos = Math.max(0, $(target).offset().top - 80);
            $root.animate({scrollTop: pos  }, 500);
        }

        // clicking on an anchor
        $('a[href^="#"]:not([data-toggle])').click(function () {
            var href = $.attr(this, 'href');
            if (href.length > 2) {
                smoothScrollingTo(href);
                return false;
            }
        });

        // if page itself already contains an anchor
        smoothScrollingTo(location.hash);

        // open up tab if url has a hash that is a tab's id
        var hash = document.location.hash;
        var prefix = "";
        if (hash) {
            $('.nav-tabs a[href="'+hash.replace(prefix,"")+'"]').tab('show');
        }

        // Change hash for page-reload
        // With HTML5 history API, we can easily prevent scrolling!
        $('.nav-tabs a').on('shown.bs.tab', function (e) {
            if(history.pushState) {
                history.pushState(null, null, e.target.hash);
            } else {
                window.location.hash = e.target.hash; //Polyfill for old browsers
            }
        });
    };


    u.copyToClipBoard = function(element_id){
        try{
            var scrollPos = $(window).scrollTop();
            var aux = document.createElement("div");
            aux.setAttribute("contentEditable", true);
            aux.innerHTML = document.getElementById(element_id).innerHTML;
            aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");
            document.body.appendChild(aux);
            aux.focus();
            document.execCommand("copy");
            document.body.removeChild(aux);

            u.clearSelection();
            u.showSuccess(u.translate('copy.success'));

            $(window).scrollTop(scrollPos);
        } catch (e) {

        }
    };

    u.clearSelection = function() {
        var sel;
        if ( (sel = document.selection) && sel.empty ) {
            sel.empty();
        } else {
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            }
            var activeEl = document.activeElement;
            if (activeEl) {
                var tagName = activeEl.nodeName.toLowerCase();
                if ( tagName == "textarea" ||
                    (tagName == "input" && activeEl.type == "text") ) {
                    // Collapse the selection to the end
                    activeEl.selectionStart = activeEl.selectionEnd;
                }
            }
        }
    }

    return main;

}(window.allcaps || {}, jQuery, t, r);

// shorthand to translate keys
trans = function(key, fallback){ return allcaps.utils.translate(key,fallback);};
